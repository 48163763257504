/**
 * 说明：服务切换教材
*/

import * as teachingMaterialEnums from './teachingMaterialEnums'

const {
  PERIOD,
  SUBJECT,
  EDITION,
  Bookbooks,
  primaryBookList,
  secondaryBookList
} = teachingMaterialEnums

type LableValue = {
  label: string,
  value: string,
  children?: LableValue[]
}

/* ======================= 册别--start ======================= */
const secondaryPhysicsMergeNineGradeList = [
  { label: '八年级（上）', value: Bookbooks.Grade8One },
  { label: '八年级（下）', value: Bookbooks.Grade8Two },
  { label: '九年级', value: Bookbooks.Grade9One }
]

const secondaryPhysicsGradeList = [
  { label: '八年级（上）', value: Bookbooks.Grade8One },
  { label: '八年级（下）', value: Bookbooks.Grade8Two },
  { label: '九年级（上）', value: Bookbooks.Grade9One },
  { label: '九年级（下）', value: Bookbooks.Grade9Two }
]

const secondaryMath2024GradeList = [
  { label: '七年级（上）', value: Bookbooks.Grade7One }
]

const secondaryPhysics2024GradeList = [
  { label: '八年级（上）', value: Bookbooks.Grade8One }
]

const secondaryReviewRoundsList = [
  { label: '一轮复习', value: Bookbooks.ReviewRound1 },
  { label: '二轮复习', value: Bookbooks.ReviewRound2 }
]

function setGrades(period: enumsTypes.PERIOD, subject: enumsTypes.SUBJECT, edition: enumsTypes.EDITION): LableValue[] {
  switch (period) {
    case PERIOD.PRIMARY_SCHOOL:
      return primaryBookList
    case PERIOD.SECONDARY_SCHOOL:
      if (edition === EDITION.MSEEReview) return secondaryReviewRoundsList
      if (subject === SUBJECT.PHYSICS) {
        switch (edition) {
          case EDITION.PEP_EDITION:
          case EDITION.BJNU_EDITION:
            return secondaryPhysicsMergeNineGradeList
          case EDITION.PEP_EDITION_2024:
          case EDITION.BJNU_EDITION_2024:
            return secondaryPhysics2024GradeList
          case EDITION.PSPRESS:
            return secondaryPhysicsGradeList
        }
      } else if (subject === SUBJECT.MATH) {
        switch (edition) {
          case EDITION.PEP_EDITION_2024:
          case EDITION.BJNU_EDITION_2024:
            return secondaryMath2024GradeList
        }
      }
      return secondaryBookList
    case PERIOD.HIGH_SCHOOL:
      return getHighGradeBySubjectAndEdition(subject, edition)
    default:
      return []
  }
}

function getHighGradeBySubjectAndEdition(subject: enumsTypes.SUBJECT, edition: enumsTypes.EDITION): LableValue[] {
  if (subject === SUBJECT.MATH) {
    switch (edition) {
      case EDITION.PEPEditionA2019:
        return getBiXuan(['b1', 'b2', 'x1', 'x2', 'x3'])
      case EDITION.PEPEditionB2019:
        return getBiXuan(['b1', 'b2', 'b3', 'b4', 'x1', 'x2', 'x3'])
      case EDITION.PEPEditionA:
        return getBiXuan(['b1', 'b2', 'b3', 'b4', 'b5', 'x1-1', 'x1-2', 'x2-1', 'x2-2', 'x2-3', 'x4-1', 'x4-2', 'x4-4', 'x4-5'])
      case EDITION.BJNU_EDITION:
        return getBiXuan(['b1', 'b2', 'b3', 'b4', 'b5', 'x1-1', 'x1-2', 'x2-1', 'x2-2', 'x2-3', 'x4-1', 'x4-2', 'x4-4', 'x4-5'])
      case EDITION.CollegeEntranceReview:
        return getBiXuan(['f-1', 'f-2', 'f-3'])
      default:
        return []
    }
  } else if (subject === SUBJECT.PHYSICS) {
    switch (edition) {
      case EDITION.PEPEdition2019:
        return getBiXuan(['b1', 'b2', 'b3', 'x1', 'x2', 'x3'])
      case EDITION.CollegeEntranceReview:
        return getBiXuan(['f-1', 'f-2', 'f-3'])
      default:
        return []
    }
  } else if (subject === SUBJECT.CHEMISTRY) {
    switch (edition) {
      case EDITION.PEPEdition2019:
        return getBiXuan(['b1', 'b2', 'x1', 'x2', 'x3'])
      case EDITION.PEP_EDITION:
        return getBiXuan(['b1', 'b2', 'x1', 'x2', 'x3', 'x4', 'x5', 'x6'])
      case EDITION.LKJEdition2019:
        return getBiXuan(['b1', 'b2', 'x1', 'x2', 'x3'])
      case EDITION.CollegeEntranceReview:
        return getBiXuan(['f-1', 'f-2', 'f-3'])
      default:
        return []
    }
  }
  return []
}

function getBiXuan(keys: string[]): LableValue[] {
  const buff: { [key: string]: LableValue } = {
    'b1': { label: '必修一', value: Bookbooks.Required1 },
    'b2': { label: '必修二', value: Bookbooks.Required2 },
    'b3': { label: '必修三', value: Bookbooks.Required3 },
    'b4': { label: '必修四', value: Bookbooks.Required3 },
    'b5': { label: '必修五', value: Bookbooks.Required5 },

    'x1': { label: '选修一', value: Bookbooks.Elective1 },
    'x2': { label: '选修二', value: Bookbooks.Elective2 },
    'x3': { label: '选修三', value: Bookbooks.Elective3 },
    'x4': { label: '选修四', value: Bookbooks.Elective4 },
    'x5': { label: '选修五', value: Bookbooks.Elective5 },
    'x6': { label: '选修六', value: Bookbooks.Elective6 },

    'x1-1': { label: '选修1-1', value: Bookbooks.Elective1_1 },
    'x1-2': { label: '选修1-2', value: Bookbooks.Elective1_2 },
    'x2-1': { label: '选修2-1', value: Bookbooks.Elective2_1 },
    'x2-2': { label: '选修2-2', value: Bookbooks.Elective2_2 },
    'x2-3': { label: '选修2-3', value: Bookbooks.Elective2_3 },
    'x4-1': { label: '选修4-1', value: Bookbooks.Elective4_1 },
    'x4-2': { label: '选修4-2', value: Bookbooks.Elective4_2 },
    'x4-4': { label: '选修4-4', value: Bookbooks.Elective4_4 },
    'x4-5': { label: '选修4-5', value: Bookbooks.Elective4_5 },

    'f-1': { label: '一轮复习', value: Bookbooks.ReviewRound1 },
    'f-2': { label: '二轮复习', value: Bookbooks.ReviewRound2 },
    'f-3': { label: '三轮复习', value: Bookbooks.ReviewRound3 }
  }
  const res = []
  for (const key of keys) {
    if (buff[key]) {
      res.push(buff[key])
    }
  }
  return res
}
/* ======================= 册别--end ======================= */

/* ======================= 版本--start ======================= */
const primaryEditions = [
  { label: '北师大版', value: EDITION.BJNU_EDITION },
  { label: '人教版', value: EDITION.PEP_EDITION },
  { label: '苏教版', value: EDITION.JIANGSU_EDITION }
]

const secondaryEditions = [
  { label: '北师大版', value: EDITION.BJNU_EDITION },
  { label: '人教版', value: EDITION.PEP_EDITION },
  { label: '苏教版', value: EDITION.JIANGSU_EDITION },
  { label: '华师版', value: EDITION.ECNU_EDITION },
  { label: '浙教版', value: EDITION.ZHE_JIANG_EDITION },
  { label: '初中总复习', value: EDITION.MSEEReview }
]

const secondaryMathEditions = [
  { label: '北师大版(2012)', value: EDITION.BJNU_EDITION },
  { label: '北师大版(2024)', value: EDITION.BJNU_EDITION_2024 },
  { label: '人教版(2012)', value: EDITION.PEP_EDITION },
  { label: '人教版(2024)', value: EDITION.PEP_EDITION_2024 },
  { label: '苏教版', value: EDITION.JIANGSU_EDITION },
  { label: '华师版', value: EDITION.ECNU_EDITION },
  { label: '浙教版', value: EDITION.ZHE_JIANG_EDITION },
  { label: '初中总复习', value: EDITION.MSEEReview }
]

const secondaryPhysicsEditions = [
  { label: '北师大版(2012)', value: EDITION.BJNU_EDITION },
  { label: '北师大版(2024)', value: EDITION.BJNU_EDITION_2024 },
  { label: '人教版(2012)', value: EDITION.PEP_EDITION },
  { label: '人教版(2024)', value: EDITION.PEP_EDITION_2024 },
  { label: '苏科版', value: EDITION.PSPRESS },
  { label: '初中总复习', value: EDITION.MSEEReview }
]

const highMathEditions = [
  { label: '人教A版(2019)', value: EDITION.PEPEditionA2019 },
  { label: '人教B版(2019)', value: EDITION.PEPEditionB2019 },
  { label: '人教A版', value: EDITION.PEPEditionA },
  { label: '北师大版', value: EDITION.BJNU_EDITION },
  { label: '高中总复习', value: EDITION.CollegeEntranceReview }
]

const highPhysicsEditions = [
  { label: '人教版(2019)', value: EDITION.PEPEdition2019 },
  { label: '高中总复习', value: EDITION.CollegeEntranceReview }
]

const highChemistryEditions = [
  { label: '人教版(2019)', value: EDITION.PEPEdition2019 },
  { label: '人教版', value: EDITION.PEP_EDITION },
  { label: '鲁科版(2019)', value: EDITION.LKJEdition2019 },
  { label: '高中总复习', value: EDITION.CollegeEntranceReview }
]

function setEditionSelectList(period: enumsTypes.PERIOD, subject: enumsTypes.SUBJECT): LableValue[] {
  const useEditions: LableValue[] = deepCloneWithJSON(getEditions())

  for (const edition of useEditions) {
    edition.children = setGrades(period, subject, edition.value as enumsTypes.EDITION)
  }

  return useEditions

  function getEditions() {
    switch (period) {
      case PERIOD.PRIMARY_SCHOOL:
        return primaryEditions
      case PERIOD.SECONDARY_SCHOOL:
        switch (subject) {
          case SUBJECT.PHYSICS:
            return secondaryPhysicsEditions
          case SUBJECT.MATH:
            return secondaryMathEditions
          default:
            return secondaryEditions
        }
      case PERIOD.HIGH_SCHOOL:
        if (subject === SUBJECT.MATH) {
          return highMathEditions
        } else if (subject === SUBJECT.PHYSICS) {
          return highPhysicsEditions
        } else if (subject === SUBJECT.CHEMISTRY) {
          return highChemistryEditions
        } else {
          return []
        }
      default:
        return []
    }
  }
}
/* ======================= 版本--end ======================= */

/* ======================= 科目--start ======================= */
const primarySubjects = [
  { label: '数学', value: SUBJECT.MATH },
  { label: '语文', value: SUBJECT.CHINESE },
  { label: '英语', value: SUBJECT.ENGLISH }
]

const secondarySubjects = [
  { label: '数学', value: SUBJECT.MATH },
  { label: '语文', value: SUBJECT.CHINESE },
  { label: '英语', value: SUBJECT.ENGLISH },
  { label: '物理', value: SUBJECT.PHYSICS },
  { label: '化学', value: SUBJECT.CHEMISTRY },
  { label: '生物', value: SUBJECT.BIOLOGY },
  { label: '历史', value: SUBJECT.HISTORY },
  { label: '地理', value: SUBJECT.GEOGRAPHY },
  { label: '政治', value: SUBJECT.POLITICS }
]

const highSubjects = [
  { label: '数学', value: SUBJECT.MATH },
  { label: '物理', value: SUBJECT.PHYSICS },
  { label: '化学', value: SUBJECT.CHEMISTRY }
]

function setSubjectSelectList(period: enumsTypes.PERIOD): LableValue[] {
  const useSubjects: LableValue[] = deepCloneWithJSON(getSubjects())

  for (const subject of useSubjects) {
    subject.children = setEditionSelectList(period, subject.value as enumsTypes.SUBJECT)
  }

  return useSubjects

  function getSubjects() {
    switch (period) {
      case PERIOD.PRIMARY_SCHOOL:
        return primarySubjects
      case PERIOD.SECONDARY_SCHOOL:
        return secondarySubjects
      case PERIOD.HIGH_SCHOOL:
        return highSubjects
      default:
        return []
    }
  }
}
/* ======================= 科目--end ======================= */

/* ======================= 学段--start ======================= */
const periods: LableValue[] = [
  {
    label: '小学',
    value: PERIOD.PRIMARY_SCHOOL,
    children: setSubjectSelectList(PERIOD.PRIMARY_SCHOOL)
  },
  {
    label: '初中',
    value: PERIOD.SECONDARY_SCHOOL,
    children: setSubjectSelectList(PERIOD.SECONDARY_SCHOOL)
  },
  {
    label: '高中',
    value: PERIOD.HIGH_SCHOOL,
    children: setSubjectSelectList(PERIOD.HIGH_SCHOOL)
  }
]
/* ======================= 学段--end ======================= */

function deepCloneWithJSON<T>(json: T): T {
  return JSON.parse(JSON.stringify(json))
}

export function getTextbookSelectList(valueList: string[]): LableValue[] {
  let items: LableValue[] = periods
  let changeMark = true

  for (const value of valueList) {
    if (!value || !items.length) return items

    changeMark = false
    for (const item of items) {
      if (item.value === value) {
        items = item.children || []
        changeMark = true
        break
      }
    }

    if (!changeMark) return []
  }

  return items
}
