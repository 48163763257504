import { default as compressedFileDetailsI8YZcy7m6GMeta } from "/app/pages/compressedFileDetails.vue?macro=true";
import { default as AreaSelect9MPJT9RFg3Meta } from "/app/pages/database/_components/AreaSelect.vue?macro=true";
import { default as GroupQuestionDialognlln7tiR59Meta } from "/app/pages/database/_components/GroupQuestionDialog.vue?macro=true";
import { default as GroupStageOnemuMmCDApJkMeta } from "/app/pages/database/_components/GroupStageOne.vue?macro=true";
import { default as GroupStageTwo5qkglj3vTAMeta } from "/app/pages/database/_components/GroupStageTwo.vue?macro=true";
import { default as StageSidebarTree4isTV1eL1DMeta } from "/app/pages/database/_components/StageSidebarTree.vue?macro=true";
import { default as TreeLeafSelecti0aTQVgrubMeta } from "/app/pages/database/_components/TreeLeafSelect.vue?macro=true";
import { default as accordChaptersZLTZQwzGlgMeta } from "/app/pages/database/accordChapters.vue?macro=true";
import { default as accordKnowledgesLanPGGWF4gMeta } from "/app/pages/database/accordKnowledges.vue?macro=true";
import { default as accordTestPaper4ptgAZIO9SMeta } from "/app/pages/database/accordTestPaper.vue?macro=true";
import { default as accordWrongQuestionn0QopGbsykMeta } from "/app/pages/database/accordWrongQuestion.vue?macro=true";
import { default as databasekR7FnvwlRAMeta } from "/app/pages/database.vue?macro=true";
import { default as ShareFormXAAKevSGpnMeta } from "/app/pages/index/_components/ShareForm.vue?macro=true";
import { default as TeacherFormgIaiJURysAMeta } from "/app/pages/index/_components/TeacherForm.vue?macro=true";
import { default as TeachersFormPermissionssHI0jzpgoWMeta } from "/app/pages/index/_components/TeachersFormPermissions.vue?macro=true";
import { default as overallPortraitdB3FrI4UEcMeta } from "/app/pages/index/home/classPortrait/[groupId]/overallPortrait.vue?macro=true";
import { default as preclassTestDZCwpToqBPMeta } from "/app/pages/index/home/classPortrait/[groupId]/preclassTest.vue?macro=true";
import { default as thisExamV06WVZD7VFMeta } from "/app/pages/index/home/classPortrait/[groupId]/thisExam.vue?macro=true";
import { default as _91groupId_93VDyNGA2d2oMeta } from "/app/pages/index/home/classPortrait/[groupId].vue?macro=true";
import { default as indexcs7P3NOKOjMeta } from "/app/pages/index/home/index.vue?macro=true";
import { default as home6q89rW5PKMMeta } from "/app/pages/index/home.vue?macro=true";
import { default as printStatusqgsHPpWNXYMeta } from "/app/pages/index/printStatus.vue?macro=true";
import { default as accountSettingsKPJ3t5qyJPMeta } from "/app/pages/index/settings/accountSettings.vue?macro=true";
import { default as printerSettingsld3cO2tMNRMeta } from "/app/pages/index/settings/printerSettings.vue?macro=true";
import { default as settings8XWC0YgHmwMeta } from "/app/pages/index/settings.vue?macro=true";
import { default as taskStatusZbrZM9AfsnMeta } from "/app/pages/index/taskStatus.vue?macro=true";
import { default as ChangeSelectAnswerVmxczJg2GQMeta } from "/app/pages/index/teachingManagement/_components/ChangeSelectAnswer.vue?macro=true";
import { default as CustomHeaderU8s0eO6Kv3Meta } from "/app/pages/index/teachingManagement/_components/CustomHeader.vue?macro=true";
import { default as ExportDataUC9gPRvsMPMeta } from "/app/pages/index/teachingManagement/_components/ExportData.vue?macro=true";
import { default as PromptPU5i6kS1NkMeta } from "/app/pages/index/teachingManagement/_components/Prompt.vue?macro=true";
import { default as modifyScoreK0kExvFUGxMeta } from "/app/pages/index/teachingManagement/exam/[examId]/_components/modifyScore.vue?macro=true";
import { default as rightMarkSliceWhSCXec88lMeta } from "/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMarkSlice.vue?macro=true";
import { default as rightMarkSliceBtnVUmiwWE7pxMeta } from "/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMarkSliceBtn.vue?macro=true";
import { default as rightMenuEczQDgXc1NMeta } from "/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMenu.vue?macro=true";
import { default as rightMenuFormao2EvIUThnMeta } from "/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMenuForm.vue?macro=true";
import { default as _91groupId_93SaG0MVw5IEMeta } from "/app/pages/index/teachingManagement/exam/[examId]/correctMergePaper/[groupId].vue?macro=true";
import { default as indexAyHE0aEpBDMeta } from "/app/pages/index/teachingManagement/exam/[examId]/correctMergePaper/index.vue?macro=true";
import { default as _91examQuestionId_93nOajJyY4zAMeta } from "/app/pages/index/teachingManagement/exam/[examId]/correctPaper/[examQuestionId].vue?macro=true";
import { default as indexqzdVFKGUBTMeta } from "/app/pages/index/teachingManagement/exam/[examId]/correctPaper/index.vue?macro=true";
import { default as correctPaperRe5VQKjAsZMeta } from "/app/pages/index/teachingManagement/exam/[examId]/correctPaper.vue?macro=true";
import { default as _91scanId_932fP5PWKBx0Meta } from "/app/pages/index/teachingManagement/exam/[examId]/handleAnswerSheet/[correctType]/[scanId].vue?macro=true";
import { default as indexIkYB3iBD6oMeta } from "/app/pages/index/teachingManagement/exam/[examId]/handleAnswerSheet/[correctType]/index.vue?macro=true";
import { default as indexxKO6cYQVqCMeta } from "/app/pages/index/teachingManagement/exam/[examId]/index.vue?macro=true";
import { default as indexOuPuS4KGm7Meta } from "/app/pages/index/teachingManagement/exam/[examId]/inputChoiceAnswer/index.vue?macro=true";
import { default as slice2A8mvYO2ezeMeta } from "/app/pages/index/teachingManagement/exam/[examId]/slice2.vue?macro=true";
import { default as _91studentId_93YwGHkm7wpOMeta } from "/app/pages/index/teachingManagement/exam/[examId]/studentPortrait/[studentId].vue?macro=true";
import { default as indexb72dLtHCMvMeta } from "/app/pages/index/teachingManagement/exam/[examId]/studentPortrait/index.vue?macro=true";
import { default as _91studentId_93ZOAVILsWmSMeta } from "/app/pages/index/teachingManagement/exam/[examId]/studentTestPaper/[studentId].vue?macro=true";
import { default as indexLJW07ToRpBMeta } from "/app/pages/index/teachingManagement/exam/[examId]/studentTestPaper/index.vue?macro=true";
import { default as AddNewExamfHV93qu05XMeta } from "/app/pages/index/teachingManagement/exam/_components/AddNewExam.vue?macro=true";
import { default as UploadAnswerSheetkspj3belX7Meta } from "/app/pages/index/teachingManagement/exam/_components/UploadAnswerSheet.vue?macro=true";
import { default as UploadStandardAnswerkeZ0IjLRKNMeta } from "/app/pages/index/teachingManagement/exam/_components/UploadStandardAnswer.vue?macro=true";
import { default as indexfZJ9uAVjqhMeta } from "/app/pages/index/teachingManagement/exam/index.vue?macro=true";
import { default as setTargetScore2EYGMV2l74Meta } from "/app/pages/index/teachingManagement/exam/setTargetScore.vue?macro=true";
import { default as examTL8JZVBgTYMeta } from "/app/pages/index/teachingManagement/exam.vue?macro=true";
import { default as classScoreChartoXjrSW1eq7Meta } from "/app/pages/index/teachingManagement/group/[id]/classScoreChart.vue?macro=true";
import { default as indexnmc7JlvJDqMeta } from "/app/pages/index/teachingManagement/group/[id]/index.vue?macro=true";
import { default as studyReporthk5p8qO8WHMeta } from "/app/pages/index/teachingManagement/group/[id]/studyReport.vue?macro=true";
import { default as indextOUaozpnYSMeta } from "/app/pages/index/teachingManagement/group/index.vue?macro=true";
import { default as group9vbnOtaUIEMeta } from "/app/pages/index/teachingManagement/group.vue?macro=true";
import { default as _91scanId_93NQjWCNtfMZMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctHomework/handleAnswerSheet/[scanId].vue?macro=true";
import { default as indexHSm4Em20qzMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctHomework/handleAnswerSheet/index.vue?macro=true";
import { default as indexaepZdIN3pQMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctHomework/index.vue?macro=true";
import { default as _91homeworkQuestionId_93FESt4FFlycMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctSeparateHomework/[homeworkQuestionId].vue?macro=true";
import { default as indexlrt5KZlMJvMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctSeparateHomework/index.vue?macro=true";
import { default as correctSeparateHomeworkTbKcG8WxOCMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctSeparateHomework.vue?macro=true";
import { default as indexp8bc4F5jm2Meta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/index.vue?macro=true";
import { default as _91classId_939xey4gkCuLMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/portait/class/[classId].vue?macro=true";
import { default as _91studentId_93e28YhBiftSMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/portait/student/[studentId].vue?macro=true";
import { default as _91studentId_93EDEec3qTY8Meta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/studentTestPaper/[studentId].vue?macro=true";
import { default as indexywMAOuc9XuMeta } from "/app/pages/index/teachingManagement/homework/group/[homeworkId]/studentTestPaper/index.vue?macro=true";
import { default as ExportDataUUjEL2AVqxMeta } from "/app/pages/index/teachingManagement/homework/group/_components/ExportData.vue?macro=true";
import { default as SetCorrectFormQBmHoUuGufMeta } from "/app/pages/index/teachingManagement/homework/group/_components/SetCorrectForm.vue?macro=true";
import { default as SetCorrectNumJ0ct1KOvt8Meta } from "/app/pages/index/teachingManagement/homework/group/_components/SetCorrectNum.vue?macro=true";
import { default as removeloYe0n04UDMeta } from "/app/pages/index/teachingManagement/homework/group/_components/remove.vue?macro=true";
import { default as uploadUnUoUC4NogMeta } from "/app/pages/index/teachingManagement/homework/group/_components/upload.vue?macro=true";
import { default as indexnwyG03ym5fMeta } from "/app/pages/index/teachingManagement/homework/group/index.vue?macro=true";
import { default as index9epi9aae75Meta } from "/app/pages/index/teachingManagement/homework/index.vue?macro=true";
import { default as indexw18qlWf5yWMeta } from "/app/pages/index/teachingManagement/homework/person/[workId]/correctWork/index.vue?macro=true";
import { default as index4yfQoZlbTlMeta } from "/app/pages/index/teachingManagement/homework/person/[workId]/index.vue?macro=true";
import { default as _91studentId_93g3GLBT1bDxMeta } from "/app/pages/index/teachingManagement/homework/person/[workId]/portait/student/[studentId].vue?macro=true";
import { default as _91studentId_93LVw88oFBDVMeta } from "/app/pages/index/teachingManagement/homework/person/[workId]/studentTestPaper/[studentId].vue?macro=true";
import { default as ExportData7twiiN5JsIMeta } from "/app/pages/index/teachingManagement/homework/person/_components/ExportData.vue?macro=true";
import { default as MainrlFAjN7brgMeta } from "/app/pages/index/teachingManagement/homework/person/_components/Preview/Main.vue?macro=true";
import { default as indexpLETgqSKtvMeta } from "/app/pages/index/teachingManagement/homework/person/_components/Preview/index.vue?macro=true";
import { default as generate9ZVh4W7SbhMeta } from "/app/pages/index/teachingManagement/homework/person/_components/generate.vue?macro=true";
import { default as personSelectAnswerEjIz6Cjxs4Meta } from "/app/pages/index/teachingManagement/homework/person/_components/personSelectAnswer.vue?macro=true";
import { default as removehtAw4sxJKlMeta } from "/app/pages/index/teachingManagement/homework/person/_components/remove.vue?macro=true";
import { default as studentListDialoglNr62xzKShMeta } from "/app/pages/index/teachingManagement/homework/person/_components/studentListDialog.vue?macro=true";
import { default as studentSelectorRLzW0h1GniMeta } from "/app/pages/index/teachingManagement/homework/person/_components/studentSelector.vue?macro=true";
import { default as uploadOGLklwN96SMeta } from "/app/pages/index/teachingManagement/homework/person/_components/upload.vue?macro=true";
import { default as indexmP4sC9nOadMeta } from "/app/pages/index/teachingManagement/homework/person/index.vue?macro=true";
import { default as homeworkje8PfzODKAMeta } from "/app/pages/index/teachingManagement/homework.vue?macro=true";
import { default as indexht3egDFQJvMeta } from "/app/pages/index/teachingManagement/student/[id]/index.vue?macro=true";
import { default as portaitNGI2SkEUbXMeta } from "/app/pages/index/teachingManagement/student/[id]/portait.vue?macro=true";
import { default as studyReportRKbXhcs7F0Meta } from "/app/pages/index/teachingManagement/student/[id]/studyReport.vue?macro=true";
import { default as index1pAE6TyilLMeta } from "/app/pages/index/teachingManagement/student/index.vue?macro=true";
import { default as student9U36D3FwVHMeta } from "/app/pages/index/teachingManagement/student.vue?macro=true";
import { default as indexHxAG3IaXIPMeta } from "/app/pages/index/teachingManagement/teacher/[id]/index.vue?macro=true";
import { default as viewTeachingSituationurMhTnFMUjMeta } from "/app/pages/index/teachingManagement/teacher/[id]/viewTeachingSituation.vue?macro=true";
import { default as indexWIErWWfTDvMeta } from "/app/pages/index/teachingManagement/teacher/index.vue?macro=true";
import { default as teacherbl9b7WexMYMeta } from "/app/pages/index/teachingManagement/teacher.vue?macro=true";
import { default as teachingManagementzXYRHp7PRaMeta } from "/app/pages/index/teachingManagement.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexq9Dp5bKr2HMeta } from "/app/pages/prepareLessons/chapter/[chapterId]/index.vue?macro=true";
import { default as indexWuWxc023n3Meta } from "/app/pages/prepareLessons/chapter/[chapterId]/lesson/[lessonId]/index.vue?macro=true";
import { default as _91lessonId_935QmDueVnyIMeta } from "/app/pages/prepareLessons/chapter/[chapterId]/lesson/[lessonId].vue?macro=true";
import { default as _91chapterId_93d3TaHssrYYMeta } from "/app/pages/prepareLessons/chapter/[chapterId].vue?macro=true";
import { default as prepareLessonstcdXksyoUIMeta } from "/app/pages/prepareLessons.vue?macro=true";
import { default as indexe0Sz3ZnDNjMeta } from "/app/pages/testPaper/index.vue?macro=true";
import { default as indexuadk034orbMeta } from "/app/pages/testPaper/record/[id]/index.vue?macro=true";
import { default as indexvdgkXUBzHkMeta } from "/app/pages/testPaper/record/index.vue?macro=true";
import { default as testPaperp71y7yWAQ6Meta } from "/app/pages/testPaper.vue?macro=true";
import { default as _91id_93qtmMWExK4TMeta } from "/app/pages/viewTestPaper/[id].vue?macro=true";
import { default as viewTestPapermF4F8un0m7Meta } from "/app/pages/viewTestPaper.vue?macro=true";
export default [
  {
    name: compressedFileDetailsI8YZcy7m6GMeta?.name ?? "compressedFileDetails",
    path: compressedFileDetailsI8YZcy7m6GMeta?.path ?? "/compressedFileDetails",
    meta: compressedFileDetailsI8YZcy7m6GMeta || {},
    alias: compressedFileDetailsI8YZcy7m6GMeta?.alias || [],
    redirect: compressedFileDetailsI8YZcy7m6GMeta?.redirect || undefined,
    component: () => import("/app/pages/compressedFileDetails.vue").then(m => m.default || m)
  },
  {
    name: databasekR7FnvwlRAMeta?.name ?? "database",
    path: databasekR7FnvwlRAMeta?.path ?? "/database",
    children: [
  {
    name: AreaSelect9MPJT9RFg3Meta?.name ?? "database-_components-AreaSelect",
    path: AreaSelect9MPJT9RFg3Meta?.path ?? "_components/AreaSelect",
    meta: AreaSelect9MPJT9RFg3Meta || {},
    alias: AreaSelect9MPJT9RFg3Meta?.alias || [],
    redirect: AreaSelect9MPJT9RFg3Meta?.redirect || undefined,
    component: () => import("/app/pages/database/_components/AreaSelect.vue").then(m => m.default || m)
  },
  {
    name: GroupQuestionDialognlln7tiR59Meta?.name ?? "database-_components-GroupQuestionDialog",
    path: GroupQuestionDialognlln7tiR59Meta?.path ?? "_components/GroupQuestionDialog",
    meta: GroupQuestionDialognlln7tiR59Meta || {},
    alias: GroupQuestionDialognlln7tiR59Meta?.alias || [],
    redirect: GroupQuestionDialognlln7tiR59Meta?.redirect || undefined,
    component: () => import("/app/pages/database/_components/GroupQuestionDialog.vue").then(m => m.default || m)
  },
  {
    name: GroupStageOnemuMmCDApJkMeta?.name ?? "database-_components-GroupStageOne",
    path: GroupStageOnemuMmCDApJkMeta?.path ?? "_components/GroupStageOne",
    meta: GroupStageOnemuMmCDApJkMeta || {},
    alias: GroupStageOnemuMmCDApJkMeta?.alias || [],
    redirect: GroupStageOnemuMmCDApJkMeta?.redirect || undefined,
    component: () => import("/app/pages/database/_components/GroupStageOne.vue").then(m => m.default || m)
  },
  {
    name: GroupStageTwo5qkglj3vTAMeta?.name ?? "database-_components-GroupStageTwo",
    path: GroupStageTwo5qkglj3vTAMeta?.path ?? "_components/GroupStageTwo",
    meta: GroupStageTwo5qkglj3vTAMeta || {},
    alias: GroupStageTwo5qkglj3vTAMeta?.alias || [],
    redirect: GroupStageTwo5qkglj3vTAMeta?.redirect || undefined,
    component: () => import("/app/pages/database/_components/GroupStageTwo.vue").then(m => m.default || m)
  },
  {
    name: StageSidebarTree4isTV1eL1DMeta?.name ?? "database-_components-StageSidebarTree",
    path: StageSidebarTree4isTV1eL1DMeta?.path ?? "_components/StageSidebarTree",
    meta: StageSidebarTree4isTV1eL1DMeta || {},
    alias: StageSidebarTree4isTV1eL1DMeta?.alias || [],
    redirect: StageSidebarTree4isTV1eL1DMeta?.redirect || undefined,
    component: () => import("/app/pages/database/_components/StageSidebarTree.vue").then(m => m.default || m)
  },
  {
    name: TreeLeafSelecti0aTQVgrubMeta?.name ?? "database-_components-TreeLeafSelect",
    path: TreeLeafSelecti0aTQVgrubMeta?.path ?? "_components/TreeLeafSelect",
    meta: TreeLeafSelecti0aTQVgrubMeta || {},
    alias: TreeLeafSelecti0aTQVgrubMeta?.alias || [],
    redirect: TreeLeafSelecti0aTQVgrubMeta?.redirect || undefined,
    component: () => import("/app/pages/database/_components/TreeLeafSelect.vue").then(m => m.default || m)
  },
  {
    name: accordChaptersZLTZQwzGlgMeta?.name ?? "database-accordChapters",
    path: accordChaptersZLTZQwzGlgMeta?.path ?? "accordChapters",
    meta: accordChaptersZLTZQwzGlgMeta || {},
    alias: accordChaptersZLTZQwzGlgMeta?.alias || [],
    redirect: accordChaptersZLTZQwzGlgMeta?.redirect || undefined,
    component: () => import("/app/pages/database/accordChapters.vue").then(m => m.default || m)
  },
  {
    name: accordKnowledgesLanPGGWF4gMeta?.name ?? "database-accordKnowledges",
    path: accordKnowledgesLanPGGWF4gMeta?.path ?? "accordKnowledges",
    meta: accordKnowledgesLanPGGWF4gMeta || {},
    alias: accordKnowledgesLanPGGWF4gMeta?.alias || [],
    redirect: accordKnowledgesLanPGGWF4gMeta?.redirect || undefined,
    component: () => import("/app/pages/database/accordKnowledges.vue").then(m => m.default || m)
  },
  {
    name: accordTestPaper4ptgAZIO9SMeta?.name ?? "database-accordTestPaper",
    path: accordTestPaper4ptgAZIO9SMeta?.path ?? "accordTestPaper",
    meta: accordTestPaper4ptgAZIO9SMeta || {},
    alias: accordTestPaper4ptgAZIO9SMeta?.alias || [],
    redirect: accordTestPaper4ptgAZIO9SMeta?.redirect || undefined,
    component: () => import("/app/pages/database/accordTestPaper.vue").then(m => m.default || m)
  },
  {
    name: accordWrongQuestionn0QopGbsykMeta?.name ?? "database-accordWrongQuestion",
    path: accordWrongQuestionn0QopGbsykMeta?.path ?? "accordWrongQuestion",
    meta: accordWrongQuestionn0QopGbsykMeta || {},
    alias: accordWrongQuestionn0QopGbsykMeta?.alias || [],
    redirect: accordWrongQuestionn0QopGbsykMeta?.redirect || undefined,
    component: () => import("/app/pages/database/accordWrongQuestion.vue").then(m => m.default || m)
  }
],
    meta: databasekR7FnvwlRAMeta || {},
    alias: databasekR7FnvwlRAMeta?.alias || [],
    redirect: databasekR7FnvwlRAMeta?.redirect || undefined,
    component: () => import("/app/pages/database.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    children: [
  {
    name: ShareFormXAAKevSGpnMeta?.name ?? "index-_components-ShareForm",
    path: ShareFormXAAKevSGpnMeta?.path ?? "_components/ShareForm",
    meta: ShareFormXAAKevSGpnMeta || {},
    alias: ShareFormXAAKevSGpnMeta?.alias || [],
    redirect: ShareFormXAAKevSGpnMeta?.redirect || undefined,
    component: () => import("/app/pages/index/_components/ShareForm.vue").then(m => m.default || m)
  },
  {
    name: TeacherFormgIaiJURysAMeta?.name ?? "index-_components-TeacherForm",
    path: TeacherFormgIaiJURysAMeta?.path ?? "_components/TeacherForm",
    meta: TeacherFormgIaiJURysAMeta || {},
    alias: TeacherFormgIaiJURysAMeta?.alias || [],
    redirect: TeacherFormgIaiJURysAMeta?.redirect || undefined,
    component: () => import("/app/pages/index/_components/TeacherForm.vue").then(m => m.default || m)
  },
  {
    name: TeachersFormPermissionssHI0jzpgoWMeta?.name ?? "index-_components-TeachersFormPermissions",
    path: TeachersFormPermissionssHI0jzpgoWMeta?.path ?? "_components/TeachersFormPermissions",
    meta: TeachersFormPermissionssHI0jzpgoWMeta || {},
    alias: TeachersFormPermissionssHI0jzpgoWMeta?.alias || [],
    redirect: TeachersFormPermissionssHI0jzpgoWMeta?.redirect || undefined,
    component: () => import("/app/pages/index/_components/TeachersFormPermissions.vue").then(m => m.default || m)
  },
  {
    path: home6q89rW5PKMMeta?.path ?? "home",
    children: [
  {
    name: _91groupId_93VDyNGA2d2oMeta?.name ?? "index-home-classPortrait-groupId",
    path: _91groupId_93VDyNGA2d2oMeta?.path ?? "classPortrait/:groupId",
    children: [
  {
    name: overallPortraitdB3FrI4UEcMeta?.name ?? "index-home-classPortrait-groupId-overallPortrait",
    path: overallPortraitdB3FrI4UEcMeta?.path ?? "overallPortrait",
    meta: overallPortraitdB3FrI4UEcMeta || {},
    alias: overallPortraitdB3FrI4UEcMeta?.alias || [],
    redirect: overallPortraitdB3FrI4UEcMeta?.redirect || undefined,
    component: () => import("/app/pages/index/home/classPortrait/[groupId]/overallPortrait.vue").then(m => m.default || m)
  },
  {
    name: preclassTestDZCwpToqBPMeta?.name ?? "index-home-classPortrait-groupId-preclassTest",
    path: preclassTestDZCwpToqBPMeta?.path ?? "preclassTest",
    meta: preclassTestDZCwpToqBPMeta || {},
    alias: preclassTestDZCwpToqBPMeta?.alias || [],
    redirect: preclassTestDZCwpToqBPMeta?.redirect || undefined,
    component: () => import("/app/pages/index/home/classPortrait/[groupId]/preclassTest.vue").then(m => m.default || m)
  },
  {
    name: thisExamV06WVZD7VFMeta?.name ?? "index-home-classPortrait-groupId-thisExam",
    path: thisExamV06WVZD7VFMeta?.path ?? "thisExam",
    meta: thisExamV06WVZD7VFMeta || {},
    alias: thisExamV06WVZD7VFMeta?.alias || [],
    redirect: thisExamV06WVZD7VFMeta?.redirect || undefined,
    component: () => import("/app/pages/index/home/classPortrait/[groupId]/thisExam.vue").then(m => m.default || m)
  }
],
    meta: _91groupId_93VDyNGA2d2oMeta || {},
    alias: _91groupId_93VDyNGA2d2oMeta?.alias || [],
    redirect: _91groupId_93VDyNGA2d2oMeta?.redirect || undefined,
    component: () => import("/app/pages/index/home/classPortrait/[groupId].vue").then(m => m.default || m)
  },
  {
    name: indexcs7P3NOKOjMeta?.name ?? "index-home",
    path: indexcs7P3NOKOjMeta?.path ?? "",
    meta: indexcs7P3NOKOjMeta || {},
    alias: indexcs7P3NOKOjMeta?.alias || [],
    redirect: indexcs7P3NOKOjMeta?.redirect || undefined,
    component: () => import("/app/pages/index/home/index.vue").then(m => m.default || m)
  }
],
    name: home6q89rW5PKMMeta?.name ?? undefined,
    meta: home6q89rW5PKMMeta || {},
    alias: home6q89rW5PKMMeta?.alias || [],
    redirect: home6q89rW5PKMMeta?.redirect || undefined,
    component: () => import("/app/pages/index/home.vue").then(m => m.default || m)
  },
  {
    name: printStatusqgsHPpWNXYMeta?.name ?? "index-printStatus",
    path: printStatusqgsHPpWNXYMeta?.path ?? "printStatus",
    meta: printStatusqgsHPpWNXYMeta || {},
    alias: printStatusqgsHPpWNXYMeta?.alias || [],
    redirect: printStatusqgsHPpWNXYMeta?.redirect || undefined,
    component: () => import("/app/pages/index/printStatus.vue").then(m => m.default || m)
  },
  {
    name: settings8XWC0YgHmwMeta?.name ?? "index-settings",
    path: settings8XWC0YgHmwMeta?.path ?? "settings",
    children: [
  {
    name: accountSettingsKPJ3t5qyJPMeta?.name ?? "index-settings-accountSettings",
    path: accountSettingsKPJ3t5qyJPMeta?.path ?? "accountSettings",
    meta: accountSettingsKPJ3t5qyJPMeta || {},
    alias: accountSettingsKPJ3t5qyJPMeta?.alias || [],
    redirect: accountSettingsKPJ3t5qyJPMeta?.redirect || undefined,
    component: () => import("/app/pages/index/settings/accountSettings.vue").then(m => m.default || m)
  },
  {
    name: printerSettingsld3cO2tMNRMeta?.name ?? "index-settings-printerSettings",
    path: printerSettingsld3cO2tMNRMeta?.path ?? "printerSettings",
    meta: printerSettingsld3cO2tMNRMeta || {},
    alias: printerSettingsld3cO2tMNRMeta?.alias || [],
    redirect: printerSettingsld3cO2tMNRMeta?.redirect || undefined,
    component: () => import("/app/pages/index/settings/printerSettings.vue").then(m => m.default || m)
  }
],
    meta: settings8XWC0YgHmwMeta || {},
    alias: settings8XWC0YgHmwMeta?.alias || [],
    redirect: settings8XWC0YgHmwMeta?.redirect || undefined,
    component: () => import("/app/pages/index/settings.vue").then(m => m.default || m)
  },
  {
    name: taskStatusZbrZM9AfsnMeta?.name ?? "index-taskStatus",
    path: taskStatusZbrZM9AfsnMeta?.path ?? "taskStatus",
    meta: taskStatusZbrZM9AfsnMeta || {},
    alias: taskStatusZbrZM9AfsnMeta?.alias || [],
    redirect: taskStatusZbrZM9AfsnMeta?.redirect || undefined,
    component: () => import("/app/pages/index/taskStatus.vue").then(m => m.default || m)
  },
  {
    name: teachingManagementzXYRHp7PRaMeta?.name ?? "index-teachingManagement",
    path: teachingManagementzXYRHp7PRaMeta?.path ?? "teachingManagement",
    children: [
  {
    name: ChangeSelectAnswerVmxczJg2GQMeta?.name ?? "index-teachingManagement-_components-ChangeSelectAnswer",
    path: ChangeSelectAnswerVmxczJg2GQMeta?.path ?? "_components/ChangeSelectAnswer",
    meta: ChangeSelectAnswerVmxczJg2GQMeta || {},
    alias: ChangeSelectAnswerVmxczJg2GQMeta?.alias || [],
    redirect: ChangeSelectAnswerVmxczJg2GQMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/_components/ChangeSelectAnswer.vue").then(m => m.default || m)
  },
  {
    name: CustomHeaderU8s0eO6Kv3Meta?.name ?? "index-teachingManagement-_components-CustomHeader",
    path: CustomHeaderU8s0eO6Kv3Meta?.path ?? "_components/CustomHeader",
    meta: CustomHeaderU8s0eO6Kv3Meta || {},
    alias: CustomHeaderU8s0eO6Kv3Meta?.alias || [],
    redirect: CustomHeaderU8s0eO6Kv3Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/_components/CustomHeader.vue").then(m => m.default || m)
  },
  {
    name: ExportDataUC9gPRvsMPMeta?.name ?? "index-teachingManagement-_components-ExportData",
    path: ExportDataUC9gPRvsMPMeta?.path ?? "_components/ExportData",
    meta: ExportDataUC9gPRvsMPMeta || {},
    alias: ExportDataUC9gPRvsMPMeta?.alias || [],
    redirect: ExportDataUC9gPRvsMPMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/_components/ExportData.vue").then(m => m.default || m)
  },
  {
    name: PromptPU5i6kS1NkMeta?.name ?? "index-teachingManagement-_components-Prompt",
    path: PromptPU5i6kS1NkMeta?.path ?? "_components/Prompt",
    meta: PromptPU5i6kS1NkMeta || {},
    alias: PromptPU5i6kS1NkMeta?.alias || [],
    redirect: PromptPU5i6kS1NkMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/_components/Prompt.vue").then(m => m.default || m)
  },
  {
    path: examTL8JZVBgTYMeta?.path ?? "exam",
    children: [
  {
    name: modifyScoreK0kExvFUGxMeta?.name ?? "index-teachingManagement-exam-examId-_components-modifyScore",
    path: modifyScoreK0kExvFUGxMeta?.path ?? ":examId/_components/modifyScore",
    meta: modifyScoreK0kExvFUGxMeta || {},
    alias: modifyScoreK0kExvFUGxMeta?.alias || [],
    redirect: modifyScoreK0kExvFUGxMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/_components/modifyScore.vue").then(m => m.default || m)
  },
  {
    name: rightMarkSliceWhSCXec88lMeta?.name ?? "index-teachingManagement-exam-examId-_components-slice-rightMarkSlice",
    path: rightMarkSliceWhSCXec88lMeta?.path ?? ":examId/_components/slice/rightMarkSlice",
    meta: rightMarkSliceWhSCXec88lMeta || {},
    alias: rightMarkSliceWhSCXec88lMeta?.alias || [],
    redirect: rightMarkSliceWhSCXec88lMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMarkSlice.vue").then(m => m.default || m)
  },
  {
    name: rightMarkSliceBtnVUmiwWE7pxMeta?.name ?? "index-teachingManagement-exam-examId-_components-slice-rightMarkSliceBtn",
    path: rightMarkSliceBtnVUmiwWE7pxMeta?.path ?? ":examId/_components/slice/rightMarkSliceBtn",
    meta: rightMarkSliceBtnVUmiwWE7pxMeta || {},
    alias: rightMarkSliceBtnVUmiwWE7pxMeta?.alias || [],
    redirect: rightMarkSliceBtnVUmiwWE7pxMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMarkSliceBtn.vue").then(m => m.default || m)
  },
  {
    name: rightMenuEczQDgXc1NMeta?.name ?? "index-teachingManagement-exam-examId-_components-slice-rightMenu",
    path: rightMenuEczQDgXc1NMeta?.path ?? ":examId/_components/slice/rightMenu",
    meta: rightMenuEczQDgXc1NMeta || {},
    alias: rightMenuEczQDgXc1NMeta?.alias || [],
    redirect: rightMenuEczQDgXc1NMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMenu.vue").then(m => m.default || m)
  },
  {
    name: rightMenuFormao2EvIUThnMeta?.name ?? "index-teachingManagement-exam-examId-_components-slice-rightMenuForm",
    path: rightMenuFormao2EvIUThnMeta?.path ?? ":examId/_components/slice/rightMenuForm",
    meta: rightMenuFormao2EvIUThnMeta || {},
    alias: rightMenuFormao2EvIUThnMeta?.alias || [],
    redirect: rightMenuFormao2EvIUThnMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/_components/slice/rightMenuForm.vue").then(m => m.default || m)
  },
  {
    name: _91groupId_93SaG0MVw5IEMeta?.name ?? "index-teachingManagement-exam-examId-correctMergePaper-groupId",
    path: _91groupId_93SaG0MVw5IEMeta?.path ?? ":examId/correctMergePaper/:groupId",
    meta: _91groupId_93SaG0MVw5IEMeta || {},
    alias: _91groupId_93SaG0MVw5IEMeta?.alias || [],
    redirect: _91groupId_93SaG0MVw5IEMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/correctMergePaper/[groupId].vue").then(m => m.default || m)
  },
  {
    name: indexAyHE0aEpBDMeta?.name ?? "index-teachingManagement-exam-examId-correctMergePaper",
    path: indexAyHE0aEpBDMeta?.path ?? ":examId/correctMergePaper",
    meta: indexAyHE0aEpBDMeta || {},
    alias: indexAyHE0aEpBDMeta?.alias || [],
    redirect: indexAyHE0aEpBDMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/correctMergePaper/index.vue").then(m => m.default || m)
  },
  {
    path: correctPaperRe5VQKjAsZMeta?.path ?? ":examId/correctPaper",
    children: [
  {
    name: _91examQuestionId_93nOajJyY4zAMeta?.name ?? "index-teachingManagement-exam-examId-correctPaper-examQuestionId",
    path: _91examQuestionId_93nOajJyY4zAMeta?.path ?? ":examQuestionId",
    meta: _91examQuestionId_93nOajJyY4zAMeta || {},
    alias: _91examQuestionId_93nOajJyY4zAMeta?.alias || [],
    redirect: _91examQuestionId_93nOajJyY4zAMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/correctPaper/[examQuestionId].vue").then(m => m.default || m)
  },
  {
    name: indexqzdVFKGUBTMeta?.name ?? "index-teachingManagement-exam-examId-correctPaper",
    path: indexqzdVFKGUBTMeta?.path ?? "",
    meta: indexqzdVFKGUBTMeta || {},
    alias: indexqzdVFKGUBTMeta?.alias || [],
    redirect: indexqzdVFKGUBTMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/correctPaper/index.vue").then(m => m.default || m)
  }
],
    name: correctPaperRe5VQKjAsZMeta?.name ?? undefined,
    meta: correctPaperRe5VQKjAsZMeta || {},
    alias: correctPaperRe5VQKjAsZMeta?.alias || [],
    redirect: correctPaperRe5VQKjAsZMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/correctPaper.vue").then(m => m.default || m)
  },
  {
    name: _91scanId_932fP5PWKBx0Meta?.name ?? "index-teachingManagement-exam-examId-handleAnswerSheet-correctType-scanId",
    path: _91scanId_932fP5PWKBx0Meta?.path ?? ":examId/handleAnswerSheet/:correctType/:scanId",
    meta: _91scanId_932fP5PWKBx0Meta || {},
    alias: _91scanId_932fP5PWKBx0Meta?.alias || [],
    redirect: _91scanId_932fP5PWKBx0Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/handleAnswerSheet/[correctType]/[scanId].vue").then(m => m.default || m)
  },
  {
    name: indexIkYB3iBD6oMeta?.name ?? "index-teachingManagement-exam-examId-handleAnswerSheet-correctType",
    path: indexIkYB3iBD6oMeta?.path ?? ":examId/handleAnswerSheet/:correctType",
    meta: indexIkYB3iBD6oMeta || {},
    alias: indexIkYB3iBD6oMeta?.alias || [],
    redirect: indexIkYB3iBD6oMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/handleAnswerSheet/[correctType]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxKO6cYQVqCMeta?.name ?? "index-teachingManagement-exam-examId",
    path: indexxKO6cYQVqCMeta?.path ?? ":examId",
    meta: indexxKO6cYQVqCMeta || {},
    alias: indexxKO6cYQVqCMeta?.alias || [],
    redirect: indexxKO6cYQVqCMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOuPuS4KGm7Meta?.name ?? "index-teachingManagement-exam-examId-inputChoiceAnswer",
    path: indexOuPuS4KGm7Meta?.path ?? ":examId/inputChoiceAnswer",
    meta: indexOuPuS4KGm7Meta || {},
    alias: indexOuPuS4KGm7Meta?.alias || [],
    redirect: indexOuPuS4KGm7Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/inputChoiceAnswer/index.vue").then(m => m.default || m)
  },
  {
    name: slice2A8mvYO2ezeMeta?.name ?? "index-teachingManagement-exam-examId-slice2",
    path: slice2A8mvYO2ezeMeta?.path ?? ":examId/slice2",
    meta: slice2A8mvYO2ezeMeta || {},
    alias: slice2A8mvYO2ezeMeta?.alias || [],
    redirect: slice2A8mvYO2ezeMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/slice2.vue").then(m => m.default || m)
  },
  {
    name: _91studentId_93YwGHkm7wpOMeta?.name ?? "index-teachingManagement-exam-examId-studentPortrait-studentId",
    path: _91studentId_93YwGHkm7wpOMeta?.path ?? ":examId/studentPortrait/:studentId",
    meta: _91studentId_93YwGHkm7wpOMeta || {},
    alias: _91studentId_93YwGHkm7wpOMeta?.alias || [],
    redirect: _91studentId_93YwGHkm7wpOMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/studentPortrait/[studentId].vue").then(m => m.default || m)
  },
  {
    name: indexb72dLtHCMvMeta?.name ?? "index-teachingManagement-exam-examId-studentPortrait",
    path: indexb72dLtHCMvMeta?.path ?? ":examId/studentPortrait",
    meta: indexb72dLtHCMvMeta || {},
    alias: indexb72dLtHCMvMeta?.alias || [],
    redirect: indexb72dLtHCMvMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/studentPortrait/index.vue").then(m => m.default || m)
  },
  {
    name: _91studentId_93ZOAVILsWmSMeta?.name ?? "index-teachingManagement-exam-examId-studentTestPaper-studentId",
    path: _91studentId_93ZOAVILsWmSMeta?.path ?? ":examId/studentTestPaper/:studentId",
    meta: _91studentId_93ZOAVILsWmSMeta || {},
    alias: _91studentId_93ZOAVILsWmSMeta?.alias || [],
    redirect: _91studentId_93ZOAVILsWmSMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/studentTestPaper/[studentId].vue").then(m => m.default || m)
  },
  {
    name: indexLJW07ToRpBMeta?.name ?? "index-teachingManagement-exam-examId-studentTestPaper",
    path: indexLJW07ToRpBMeta?.path ?? ":examId/studentTestPaper",
    meta: indexLJW07ToRpBMeta || {},
    alias: indexLJW07ToRpBMeta?.alias || [],
    redirect: indexLJW07ToRpBMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/[examId]/studentTestPaper/index.vue").then(m => m.default || m)
  },
  {
    name: AddNewExamfHV93qu05XMeta?.name ?? "index-teachingManagement-exam-_components-AddNewExam",
    path: AddNewExamfHV93qu05XMeta?.path ?? "_components/AddNewExam",
    meta: AddNewExamfHV93qu05XMeta || {},
    alias: AddNewExamfHV93qu05XMeta?.alias || [],
    redirect: AddNewExamfHV93qu05XMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/_components/AddNewExam.vue").then(m => m.default || m)
  },
  {
    name: UploadAnswerSheetkspj3belX7Meta?.name ?? "index-teachingManagement-exam-_components-UploadAnswerSheet",
    path: UploadAnswerSheetkspj3belX7Meta?.path ?? "_components/UploadAnswerSheet",
    meta: UploadAnswerSheetkspj3belX7Meta || {},
    alias: UploadAnswerSheetkspj3belX7Meta?.alias || [],
    redirect: UploadAnswerSheetkspj3belX7Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/_components/UploadAnswerSheet.vue").then(m => m.default || m)
  },
  {
    name: UploadStandardAnswerkeZ0IjLRKNMeta?.name ?? "index-teachingManagement-exam-_components-UploadStandardAnswer",
    path: UploadStandardAnswerkeZ0IjLRKNMeta?.path ?? "_components/UploadStandardAnswer",
    meta: UploadStandardAnswerkeZ0IjLRKNMeta || {},
    alias: UploadStandardAnswerkeZ0IjLRKNMeta?.alias || [],
    redirect: UploadStandardAnswerkeZ0IjLRKNMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/_components/UploadStandardAnswer.vue").then(m => m.default || m)
  },
  {
    name: indexfZJ9uAVjqhMeta?.name ?? "index-teachingManagement-exam",
    path: indexfZJ9uAVjqhMeta?.path ?? "",
    meta: indexfZJ9uAVjqhMeta || {},
    alias: indexfZJ9uAVjqhMeta?.alias || [],
    redirect: indexfZJ9uAVjqhMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/index.vue").then(m => m.default || m)
  },
  {
    name: setTargetScore2EYGMV2l74Meta?.name ?? "index-teachingManagement-exam-setTargetScore",
    path: setTargetScore2EYGMV2l74Meta?.path ?? "setTargetScore",
    meta: setTargetScore2EYGMV2l74Meta || {},
    alias: setTargetScore2EYGMV2l74Meta?.alias || [],
    redirect: setTargetScore2EYGMV2l74Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam/setTargetScore.vue").then(m => m.default || m)
  }
],
    name: examTL8JZVBgTYMeta?.name ?? undefined,
    meta: examTL8JZVBgTYMeta || {},
    alias: examTL8JZVBgTYMeta?.alias || [],
    redirect: examTL8JZVBgTYMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/exam.vue").then(m => m.default || m)
  },
  {
    path: group9vbnOtaUIEMeta?.path ?? "group",
    children: [
  {
    name: classScoreChartoXjrSW1eq7Meta?.name ?? "index-teachingManagement-group-id-classScoreChart",
    path: classScoreChartoXjrSW1eq7Meta?.path ?? ":id/classScoreChart",
    meta: classScoreChartoXjrSW1eq7Meta || {},
    alias: classScoreChartoXjrSW1eq7Meta?.alias || [],
    redirect: classScoreChartoXjrSW1eq7Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/group/[id]/classScoreChart.vue").then(m => m.default || m)
  },
  {
    name: indexnmc7JlvJDqMeta?.name ?? "index-teachingManagement-group-id",
    path: indexnmc7JlvJDqMeta?.path ?? ":id",
    meta: indexnmc7JlvJDqMeta || {},
    alias: indexnmc7JlvJDqMeta?.alias || [],
    redirect: indexnmc7JlvJDqMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: studyReporthk5p8qO8WHMeta?.name ?? "index-teachingManagement-group-id-studyReport",
    path: studyReporthk5p8qO8WHMeta?.path ?? ":id/studyReport",
    meta: studyReporthk5p8qO8WHMeta || {},
    alias: studyReporthk5p8qO8WHMeta?.alias || [],
    redirect: studyReporthk5p8qO8WHMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/group/[id]/studyReport.vue").then(m => m.default || m)
  },
  {
    name: indextOUaozpnYSMeta?.name ?? "index-teachingManagement-group",
    path: indextOUaozpnYSMeta?.path ?? "",
    meta: indextOUaozpnYSMeta || {},
    alias: indextOUaozpnYSMeta?.alias || [],
    redirect: indextOUaozpnYSMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/group/index.vue").then(m => m.default || m)
  }
],
    name: group9vbnOtaUIEMeta?.name ?? undefined,
    meta: group9vbnOtaUIEMeta || {},
    alias: group9vbnOtaUIEMeta?.alias || [],
    redirect: group9vbnOtaUIEMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/group.vue").then(m => m.default || m)
  },
  {
    path: homeworkje8PfzODKAMeta?.path ?? "homework",
    children: [
  {
    name: _91scanId_93NQjWCNtfMZMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-correctHomework-handleAnswerSheet-scanId",
    path: _91scanId_93NQjWCNtfMZMeta?.path ?? "group/:homeworkId/correctHomework/handleAnswerSheet/:scanId",
    meta: _91scanId_93NQjWCNtfMZMeta || {},
    alias: _91scanId_93NQjWCNtfMZMeta?.alias || [],
    redirect: _91scanId_93NQjWCNtfMZMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctHomework/handleAnswerSheet/[scanId].vue").then(m => m.default || m)
  },
  {
    name: indexHSm4Em20qzMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-correctHomework-handleAnswerSheet",
    path: indexHSm4Em20qzMeta?.path ?? "group/:homeworkId/correctHomework/handleAnswerSheet",
    meta: indexHSm4Em20qzMeta || {},
    alias: indexHSm4Em20qzMeta?.alias || [],
    redirect: indexHSm4Em20qzMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctHomework/handleAnswerSheet/index.vue").then(m => m.default || m)
  },
  {
    name: indexaepZdIN3pQMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-correctHomework",
    path: indexaepZdIN3pQMeta?.path ?? "group/:homeworkId/correctHomework",
    meta: indexaepZdIN3pQMeta || {},
    alias: indexaepZdIN3pQMeta?.alias || [],
    redirect: indexaepZdIN3pQMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctHomework/index.vue").then(m => m.default || m)
  },
  {
    path: correctSeparateHomeworkTbKcG8WxOCMeta?.path ?? "group/:homeworkId/correctSeparateHomework",
    children: [
  {
    name: _91homeworkQuestionId_93FESt4FFlycMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-correctSeparateHomework-homeworkQuestionId",
    path: _91homeworkQuestionId_93FESt4FFlycMeta?.path ?? ":homeworkQuestionId",
    meta: _91homeworkQuestionId_93FESt4FFlycMeta || {},
    alias: _91homeworkQuestionId_93FESt4FFlycMeta?.alias || [],
    redirect: _91homeworkQuestionId_93FESt4FFlycMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctSeparateHomework/[homeworkQuestionId].vue").then(m => m.default || m)
  },
  {
    name: indexlrt5KZlMJvMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-correctSeparateHomework",
    path: indexlrt5KZlMJvMeta?.path ?? "",
    meta: indexlrt5KZlMJvMeta || {},
    alias: indexlrt5KZlMJvMeta?.alias || [],
    redirect: indexlrt5KZlMJvMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctSeparateHomework/index.vue").then(m => m.default || m)
  }
],
    name: correctSeparateHomeworkTbKcG8WxOCMeta?.name ?? undefined,
    meta: correctSeparateHomeworkTbKcG8WxOCMeta || {},
    alias: correctSeparateHomeworkTbKcG8WxOCMeta?.alias || [],
    redirect: correctSeparateHomeworkTbKcG8WxOCMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/correctSeparateHomework.vue").then(m => m.default || m)
  },
  {
    name: indexp8bc4F5jm2Meta?.name ?? "index-teachingManagement-homework-group-homeworkId",
    path: indexp8bc4F5jm2Meta?.path ?? "group/:homeworkId",
    meta: indexp8bc4F5jm2Meta || {},
    alias: indexp8bc4F5jm2Meta?.alias || [],
    redirect: indexp8bc4F5jm2Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91classId_939xey4gkCuLMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-portait-class-classId",
    path: _91classId_939xey4gkCuLMeta?.path ?? "group/:homeworkId/portait/class/:classId",
    meta: _91classId_939xey4gkCuLMeta || {},
    alias: _91classId_939xey4gkCuLMeta?.alias || [],
    redirect: _91classId_939xey4gkCuLMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/portait/class/[classId].vue").then(m => m.default || m)
  },
  {
    name: _91studentId_93e28YhBiftSMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-portait-student-studentId",
    path: _91studentId_93e28YhBiftSMeta?.path ?? "group/:homeworkId/portait/student/:studentId",
    meta: _91studentId_93e28YhBiftSMeta || {},
    alias: _91studentId_93e28YhBiftSMeta?.alias || [],
    redirect: _91studentId_93e28YhBiftSMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/portait/student/[studentId].vue").then(m => m.default || m)
  },
  {
    name: _91studentId_93EDEec3qTY8Meta?.name ?? "index-teachingManagement-homework-group-homeworkId-studentTestPaper-studentId",
    path: _91studentId_93EDEec3qTY8Meta?.path ?? "group/:homeworkId/studentTestPaper/:studentId",
    meta: _91studentId_93EDEec3qTY8Meta || {},
    alias: _91studentId_93EDEec3qTY8Meta?.alias || [],
    redirect: _91studentId_93EDEec3qTY8Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/studentTestPaper/[studentId].vue").then(m => m.default || m)
  },
  {
    name: indexywMAOuc9XuMeta?.name ?? "index-teachingManagement-homework-group-homeworkId-studentTestPaper",
    path: indexywMAOuc9XuMeta?.path ?? "group/:homeworkId/studentTestPaper",
    meta: indexywMAOuc9XuMeta || {},
    alias: indexywMAOuc9XuMeta?.alias || [],
    redirect: indexywMAOuc9XuMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/[homeworkId]/studentTestPaper/index.vue").then(m => m.default || m)
  },
  {
    name: ExportDataUUjEL2AVqxMeta?.name ?? "index-teachingManagement-homework-group-_components-ExportData",
    path: ExportDataUUjEL2AVqxMeta?.path ?? "group/_components/ExportData",
    meta: ExportDataUUjEL2AVqxMeta || {},
    alias: ExportDataUUjEL2AVqxMeta?.alias || [],
    redirect: ExportDataUUjEL2AVqxMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/_components/ExportData.vue").then(m => m.default || m)
  },
  {
    name: SetCorrectFormQBmHoUuGufMeta?.name ?? "index-teachingManagement-homework-group-_components-SetCorrectForm",
    path: SetCorrectFormQBmHoUuGufMeta?.path ?? "group/_components/SetCorrectForm",
    meta: SetCorrectFormQBmHoUuGufMeta || {},
    alias: SetCorrectFormQBmHoUuGufMeta?.alias || [],
    redirect: SetCorrectFormQBmHoUuGufMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/_components/SetCorrectForm.vue").then(m => m.default || m)
  },
  {
    name: SetCorrectNumJ0ct1KOvt8Meta?.name ?? "index-teachingManagement-homework-group-_components-SetCorrectNum",
    path: SetCorrectNumJ0ct1KOvt8Meta?.path ?? "group/_components/SetCorrectNum",
    meta: SetCorrectNumJ0ct1KOvt8Meta || {},
    alias: SetCorrectNumJ0ct1KOvt8Meta?.alias || [],
    redirect: SetCorrectNumJ0ct1KOvt8Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/_components/SetCorrectNum.vue").then(m => m.default || m)
  },
  {
    name: removeloYe0n04UDMeta?.name ?? "index-teachingManagement-homework-group-_components-remove",
    path: removeloYe0n04UDMeta?.path ?? "group/_components/remove",
    meta: removeloYe0n04UDMeta || {},
    alias: removeloYe0n04UDMeta?.alias || [],
    redirect: removeloYe0n04UDMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/_components/remove.vue").then(m => m.default || m)
  },
  {
    name: uploadUnUoUC4NogMeta?.name ?? "index-teachingManagement-homework-group-_components-upload",
    path: uploadUnUoUC4NogMeta?.path ?? "group/_components/upload",
    meta: uploadUnUoUC4NogMeta || {},
    alias: uploadUnUoUC4NogMeta?.alias || [],
    redirect: uploadUnUoUC4NogMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/_components/upload.vue").then(m => m.default || m)
  },
  {
    name: indexnwyG03ym5fMeta?.name ?? "index-teachingManagement-homework-group",
    path: indexnwyG03ym5fMeta?.path ?? "group",
    meta: indexnwyG03ym5fMeta || {},
    alias: indexnwyG03ym5fMeta?.alias || [],
    redirect: indexnwyG03ym5fMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/group/index.vue").then(m => m.default || m)
  },
  {
    name: index9epi9aae75Meta?.name ?? "index-teachingManagement-homework",
    path: index9epi9aae75Meta?.path ?? "",
    meta: index9epi9aae75Meta || {},
    alias: index9epi9aae75Meta?.alias || [],
    redirect: index9epi9aae75Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/index.vue").then(m => m.default || m)
  },
  {
    name: indexw18qlWf5yWMeta?.name ?? "index-teachingManagement-homework-person-workId-correctWork",
    path: indexw18qlWf5yWMeta?.path ?? "person/:workId/correctWork",
    meta: indexw18qlWf5yWMeta || {},
    alias: indexw18qlWf5yWMeta?.alias || [],
    redirect: indexw18qlWf5yWMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/[workId]/correctWork/index.vue").then(m => m.default || m)
  },
  {
    name: index4yfQoZlbTlMeta?.name ?? "index-teachingManagement-homework-person-workId",
    path: index4yfQoZlbTlMeta?.path ?? "person/:workId",
    meta: index4yfQoZlbTlMeta || {},
    alias: index4yfQoZlbTlMeta?.alias || [],
    redirect: index4yfQoZlbTlMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/[workId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91studentId_93g3GLBT1bDxMeta?.name ?? "index-teachingManagement-homework-person-workId-portait-student-studentId",
    path: _91studentId_93g3GLBT1bDxMeta?.path ?? "person/:workId/portait/student/:studentId",
    meta: _91studentId_93g3GLBT1bDxMeta || {},
    alias: _91studentId_93g3GLBT1bDxMeta?.alias || [],
    redirect: _91studentId_93g3GLBT1bDxMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/[workId]/portait/student/[studentId].vue").then(m => m.default || m)
  },
  {
    name: _91studentId_93LVw88oFBDVMeta?.name ?? "index-teachingManagement-homework-person-workId-studentTestPaper-studentId",
    path: _91studentId_93LVw88oFBDVMeta?.path ?? "person/:workId/studentTestPaper/:studentId",
    meta: _91studentId_93LVw88oFBDVMeta || {},
    alias: _91studentId_93LVw88oFBDVMeta?.alias || [],
    redirect: _91studentId_93LVw88oFBDVMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/[workId]/studentTestPaper/[studentId].vue").then(m => m.default || m)
  },
  {
    name: ExportData7twiiN5JsIMeta?.name ?? "index-teachingManagement-homework-person-_components-ExportData",
    path: ExportData7twiiN5JsIMeta?.path ?? "person/_components/ExportData",
    meta: ExportData7twiiN5JsIMeta || {},
    alias: ExportData7twiiN5JsIMeta?.alias || [],
    redirect: ExportData7twiiN5JsIMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/ExportData.vue").then(m => m.default || m)
  },
  {
    name: MainrlFAjN7brgMeta?.name ?? "index-teachingManagement-homework-person-_components-Preview-Main",
    path: MainrlFAjN7brgMeta?.path ?? "person/_components/Preview/Main",
    meta: MainrlFAjN7brgMeta || {},
    alias: MainrlFAjN7brgMeta?.alias || [],
    redirect: MainrlFAjN7brgMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/Preview/Main.vue").then(m => m.default || m)
  },
  {
    name: indexpLETgqSKtvMeta?.name ?? "index-teachingManagement-homework-person-_components-Preview",
    path: indexpLETgqSKtvMeta?.path ?? "person/_components/Preview",
    meta: indexpLETgqSKtvMeta || {},
    alias: indexpLETgqSKtvMeta?.alias || [],
    redirect: indexpLETgqSKtvMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/Preview/index.vue").then(m => m.default || m)
  },
  {
    name: generate9ZVh4W7SbhMeta?.name ?? "index-teachingManagement-homework-person-_components-generate",
    path: generate9ZVh4W7SbhMeta?.path ?? "person/_components/generate",
    meta: generate9ZVh4W7SbhMeta || {},
    alias: generate9ZVh4W7SbhMeta?.alias || [],
    redirect: generate9ZVh4W7SbhMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/generate.vue").then(m => m.default || m)
  },
  {
    name: personSelectAnswerEjIz6Cjxs4Meta?.name ?? "index-teachingManagement-homework-person-_components-personSelectAnswer",
    path: personSelectAnswerEjIz6Cjxs4Meta?.path ?? "person/_components/personSelectAnswer",
    meta: personSelectAnswerEjIz6Cjxs4Meta || {},
    alias: personSelectAnswerEjIz6Cjxs4Meta?.alias || [],
    redirect: personSelectAnswerEjIz6Cjxs4Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/personSelectAnswer.vue").then(m => m.default || m)
  },
  {
    name: removehtAw4sxJKlMeta?.name ?? "index-teachingManagement-homework-person-_components-remove",
    path: removehtAw4sxJKlMeta?.path ?? "person/_components/remove",
    meta: removehtAw4sxJKlMeta || {},
    alias: removehtAw4sxJKlMeta?.alias || [],
    redirect: removehtAw4sxJKlMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/remove.vue").then(m => m.default || m)
  },
  {
    name: studentListDialoglNr62xzKShMeta?.name ?? "index-teachingManagement-homework-person-_components-studentListDialog",
    path: studentListDialoglNr62xzKShMeta?.path ?? "person/_components/studentListDialog",
    meta: studentListDialoglNr62xzKShMeta || {},
    alias: studentListDialoglNr62xzKShMeta?.alias || [],
    redirect: studentListDialoglNr62xzKShMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/studentListDialog.vue").then(m => m.default || m)
  },
  {
    name: studentSelectorRLzW0h1GniMeta?.name ?? "index-teachingManagement-homework-person-_components-studentSelector",
    path: studentSelectorRLzW0h1GniMeta?.path ?? "person/_components/studentSelector",
    meta: studentSelectorRLzW0h1GniMeta || {},
    alias: studentSelectorRLzW0h1GniMeta?.alias || [],
    redirect: studentSelectorRLzW0h1GniMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/studentSelector.vue").then(m => m.default || m)
  },
  {
    name: uploadOGLklwN96SMeta?.name ?? "index-teachingManagement-homework-person-_components-upload",
    path: uploadOGLklwN96SMeta?.path ?? "person/_components/upload",
    meta: uploadOGLklwN96SMeta || {},
    alias: uploadOGLklwN96SMeta?.alias || [],
    redirect: uploadOGLklwN96SMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/_components/upload.vue").then(m => m.default || m)
  },
  {
    name: indexmP4sC9nOadMeta?.name ?? "index-teachingManagement-homework-person",
    path: indexmP4sC9nOadMeta?.path ?? "person",
    meta: indexmP4sC9nOadMeta || {},
    alias: indexmP4sC9nOadMeta?.alias || [],
    redirect: indexmP4sC9nOadMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework/person/index.vue").then(m => m.default || m)
  }
],
    name: homeworkje8PfzODKAMeta?.name ?? undefined,
    meta: homeworkje8PfzODKAMeta || {},
    alias: homeworkje8PfzODKAMeta?.alias || [],
    redirect: homeworkje8PfzODKAMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/homework.vue").then(m => m.default || m)
  },
  {
    path: student9U36D3FwVHMeta?.path ?? "student",
    children: [
  {
    name: indexht3egDFQJvMeta?.name ?? "index-teachingManagement-student-id",
    path: indexht3egDFQJvMeta?.path ?? ":id",
    meta: indexht3egDFQJvMeta || {},
    alias: indexht3egDFQJvMeta?.alias || [],
    redirect: indexht3egDFQJvMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/student/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: portaitNGI2SkEUbXMeta?.name ?? "index-teachingManagement-student-id-portait",
    path: portaitNGI2SkEUbXMeta?.path ?? ":id/portait",
    meta: portaitNGI2SkEUbXMeta || {},
    alias: portaitNGI2SkEUbXMeta?.alias || [],
    redirect: portaitNGI2SkEUbXMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/student/[id]/portait.vue").then(m => m.default || m)
  },
  {
    name: studyReportRKbXhcs7F0Meta?.name ?? "index-teachingManagement-student-id-studyReport",
    path: studyReportRKbXhcs7F0Meta?.path ?? ":id/studyReport",
    meta: studyReportRKbXhcs7F0Meta || {},
    alias: studyReportRKbXhcs7F0Meta?.alias || [],
    redirect: studyReportRKbXhcs7F0Meta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/student/[id]/studyReport.vue").then(m => m.default || m)
  },
  {
    name: index1pAE6TyilLMeta?.name ?? "index-teachingManagement-student",
    path: index1pAE6TyilLMeta?.path ?? "",
    meta: index1pAE6TyilLMeta || {},
    alias: index1pAE6TyilLMeta?.alias || [],
    redirect: index1pAE6TyilLMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/student/index.vue").then(m => m.default || m)
  }
],
    name: student9U36D3FwVHMeta?.name ?? undefined,
    meta: student9U36D3FwVHMeta || {},
    alias: student9U36D3FwVHMeta?.alias || [],
    redirect: student9U36D3FwVHMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/student.vue").then(m => m.default || m)
  },
  {
    path: teacherbl9b7WexMYMeta?.path ?? "teacher",
    children: [
  {
    name: indexHxAG3IaXIPMeta?.name ?? "index-teachingManagement-teacher-id",
    path: indexHxAG3IaXIPMeta?.path ?? ":id",
    meta: indexHxAG3IaXIPMeta || {},
    alias: indexHxAG3IaXIPMeta?.alias || [],
    redirect: indexHxAG3IaXIPMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/teacher/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: viewTeachingSituationurMhTnFMUjMeta?.name ?? "index-teachingManagement-teacher-id-viewTeachingSituation",
    path: viewTeachingSituationurMhTnFMUjMeta?.path ?? ":id/viewTeachingSituation",
    meta: viewTeachingSituationurMhTnFMUjMeta || {},
    alias: viewTeachingSituationurMhTnFMUjMeta?.alias || [],
    redirect: viewTeachingSituationurMhTnFMUjMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/teacher/[id]/viewTeachingSituation.vue").then(m => m.default || m)
  },
  {
    name: indexWIErWWfTDvMeta?.name ?? "index-teachingManagement-teacher",
    path: indexWIErWWfTDvMeta?.path ?? "",
    meta: indexWIErWWfTDvMeta || {},
    alias: indexWIErWWfTDvMeta?.alias || [],
    redirect: indexWIErWWfTDvMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/teacher/index.vue").then(m => m.default || m)
  }
],
    name: teacherbl9b7WexMYMeta?.name ?? undefined,
    meta: teacherbl9b7WexMYMeta || {},
    alias: teacherbl9b7WexMYMeta?.alias || [],
    redirect: teacherbl9b7WexMYMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement/teacher.vue").then(m => m.default || m)
  }
],
    meta: teachingManagementzXYRHp7PRaMeta || {},
    alias: teachingManagementzXYRHp7PRaMeta?.alias || [],
    redirect: teachingManagementzXYRHp7PRaMeta?.redirect || undefined,
    component: () => import("/app/pages/index/teachingManagement.vue").then(m => m.default || m)
  }
],
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: prepareLessonstcdXksyoUIMeta?.name ?? "prepareLessons",
    path: prepareLessonstcdXksyoUIMeta?.path ?? "/prepareLessons",
    children: [
  {
    path: _91chapterId_93d3TaHssrYYMeta?.path ?? "chapter/:chapterId",
    children: [
  {
    name: indexq9Dp5bKr2HMeta?.name ?? "prepareLessons-chapter-chapterId",
    path: indexq9Dp5bKr2HMeta?.path ?? "",
    meta: indexq9Dp5bKr2HMeta || {},
    alias: indexq9Dp5bKr2HMeta?.alias || [],
    redirect: indexq9Dp5bKr2HMeta?.redirect || undefined,
    component: () => import("/app/pages/prepareLessons/chapter/[chapterId]/index.vue").then(m => m.default || m)
  },
  {
    path: _91lessonId_935QmDueVnyIMeta?.path ?? "lesson/:lessonId",
    children: [
  {
    name: indexWuWxc023n3Meta?.name ?? "prepareLessons-chapter-chapterId-lesson-lessonId",
    path: indexWuWxc023n3Meta?.path ?? "",
    meta: indexWuWxc023n3Meta || {},
    alias: indexWuWxc023n3Meta?.alias || [],
    redirect: indexWuWxc023n3Meta?.redirect || undefined,
    component: () => import("/app/pages/prepareLessons/chapter/[chapterId]/lesson/[lessonId]/index.vue").then(m => m.default || m)
  }
],
    name: _91lessonId_935QmDueVnyIMeta?.name ?? undefined,
    meta: _91lessonId_935QmDueVnyIMeta || {},
    alias: _91lessonId_935QmDueVnyIMeta?.alias || [],
    redirect: _91lessonId_935QmDueVnyIMeta?.redirect || undefined,
    component: () => import("/app/pages/prepareLessons/chapter/[chapterId]/lesson/[lessonId].vue").then(m => m.default || m)
  }
],
    name: _91chapterId_93d3TaHssrYYMeta?.name ?? undefined,
    meta: _91chapterId_93d3TaHssrYYMeta || {},
    alias: _91chapterId_93d3TaHssrYYMeta?.alias || [],
    redirect: _91chapterId_93d3TaHssrYYMeta?.redirect || undefined,
    component: () => import("/app/pages/prepareLessons/chapter/[chapterId].vue").then(m => m.default || m)
  }
],
    meta: prepareLessonstcdXksyoUIMeta || {},
    alias: prepareLessonstcdXksyoUIMeta?.alias || [],
    redirect: prepareLessonstcdXksyoUIMeta?.redirect || undefined,
    component: () => import("/app/pages/prepareLessons.vue").then(m => m.default || m)
  },
  {
    path: testPaperp71y7yWAQ6Meta?.path ?? "/testPaper",
    children: [
  {
    name: indexe0Sz3ZnDNjMeta?.name ?? "testPaper",
    path: indexe0Sz3ZnDNjMeta?.path ?? "",
    meta: indexe0Sz3ZnDNjMeta || {},
    alias: indexe0Sz3ZnDNjMeta?.alias || [],
    redirect: indexe0Sz3ZnDNjMeta?.redirect || undefined,
    component: () => import("/app/pages/testPaper/index.vue").then(m => m.default || m)
  },
  {
    name: indexuadk034orbMeta?.name ?? "testPaper-record-id",
    path: indexuadk034orbMeta?.path ?? "record/:id",
    meta: indexuadk034orbMeta || {},
    alias: indexuadk034orbMeta?.alias || [],
    redirect: indexuadk034orbMeta?.redirect || undefined,
    component: () => import("/app/pages/testPaper/record/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvdgkXUBzHkMeta?.name ?? "testPaper-record",
    path: indexvdgkXUBzHkMeta?.path ?? "record",
    meta: indexvdgkXUBzHkMeta || {},
    alias: indexvdgkXUBzHkMeta?.alias || [],
    redirect: indexvdgkXUBzHkMeta?.redirect || undefined,
    component: () => import("/app/pages/testPaper/record/index.vue").then(m => m.default || m)
  }
],
    name: testPaperp71y7yWAQ6Meta?.name ?? undefined,
    meta: testPaperp71y7yWAQ6Meta || {},
    alias: testPaperp71y7yWAQ6Meta?.alias || [],
    redirect: testPaperp71y7yWAQ6Meta?.redirect || undefined,
    component: () => import("/app/pages/testPaper.vue").then(m => m.default || m)
  },
  {
    name: viewTestPapermF4F8un0m7Meta?.name ?? "viewTestPaper",
    path: viewTestPapermF4F8un0m7Meta?.path ?? "/viewTestPaper",
    children: [
  {
    name: _91id_93qtmMWExK4TMeta?.name ?? "viewTestPaper-id",
    path: _91id_93qtmMWExK4TMeta?.path ?? ":id",
    meta: _91id_93qtmMWExK4TMeta || {},
    alias: _91id_93qtmMWExK4TMeta?.alias || [],
    redirect: _91id_93qtmMWExK4TMeta?.redirect || undefined,
    component: () => import("/app/pages/viewTestPaper/[id].vue").then(m => m.default || m)
  }
],
    meta: viewTestPapermF4F8un0m7Meta || {},
    alias: viewTestPapermF4F8un0m7Meta?.alias || [],
    redirect: viewTestPapermF4F8un0m7Meta?.redirect || undefined,
    component: () => import("/app/pages/viewTestPaper.vue").then(m => m.default || m)
  }
]