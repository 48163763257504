// 根据教材信息获取章节及二级标题
export function switchTextbook(requestData: databaseTypes.TextbookInfo, key = 'database_switchTextbook') {
  const url = `/textbooks/chapters/period/{period}/subject/{subject}/edition/{edition}/grade/{grade}`
  return $request<databaseTypes.textbookModel>(key, { url, req: requestData }, { method: 'get' })
}

// 根据教材、章节、二级标题获取题目
export function getTextbookExercises(requestData: databaseTypes.GetChapterQuestionsReq, key = 'getTextbookExercises') {
  const { chapterId, sectionId, q, ...body } = requestData
  const url = `/chapters/${chapterId}/sections/${sectionId}/questions`
  return $request<databaseTypes.getQuestionsRes>(key, { url }, { method: 'post', query: { q }, body })
}
// 同上，测试用
export function getTextbookExercisesTest(requestData: databaseTypes.GetChapterQuestionsReq & { period: string }, key = 'getTextbookExercises') {
  const { chapterId, sectionId, q, period, ...body } = requestData
  const url = `https://gaoshouai.com/v1/chapters/${chapterId}/sections/${sectionId}/questions/test`
  // const url = `/chapters/${chapterId}/sections/${sectionId}/questions/test`
  return $request<databaseTypes.getQuestionsRes>(key, { url }, { method: 'post', query: { period, q }, body })
}

// 根据教材章节、二级标题获取资料
export function getTextbookMaterials(requestData: databaseTypes.GetChapterMaterialsReq, key = 'getTextbookMaterials') {
  const { chapterId, sectionId, q, ...body } = requestData
  const url = `/chapters/${chapterId}/sections/${sectionId}/materials`
  return $request(key, { url }, { method: 'post', query: { q }, body })
}

// 根据教材（学段、学科、版本、册别）获取知识点
export function getKnowledge(requestData: databaseTypes.TextbookInfo, key = 'getKnowledge') {
  const url = `/knowledgePoints`
  return $request<databaseTypes.KnowledgePoints[]>(key, { url }, { method: 'get', query: requestData })
}

// 通过知识点及其他条件筛选题目
export function getQuestions(requestData: databaseTypes.GetQuestionReq, key = 'getQuestions') {
  const { q, ...body } = requestData
  const url = `questions/filter`
  return $request(key, { url }, { method: 'post', query: { q }, body })
}
// 同上，测试用
export function getQuestionsTest(requestData: databaseTypes.GetQuestionReq & { period: string }, key = 'getQuestions') {
  const { q, period, ...body } = requestData
  const url = `https://gaoshouai.com/v1/questions/filter/test`
  // const url = `/questions/filter/test`
  return $request(key, { url }, { method: 'post', query: { period, q }, body })
}

// 根据条件搜索资料
export function getMaterials(requestData: databaseTypes.GetMaterialsReq, key = 'getMaterials') {
  const { q, ...body } = requestData
  const url = `materials/filter`
  return $request<databaseTypes.getMaterialsRes>(key, { url }, { method: 'post', query: { q }, body })
}

// 搜索前置知识点
// export function getPreKnowledge(requestData, key = 'getPreKnowledge') {
//   const { chapterId } = requestData
//   const url = `/chapters/${chapterId}/preKnowledgePoint`
//   return $request(key, { url })
// }

// 获取套卷列表
export function getTestPapers(requestData: databaseTypes.GetTestPaperReq, key = 'getTestPapers') {
  const url = '/questionsets/list'
  return $request<databaseTypes.GetTestPaperRes>(key, { url }, { method: 'post', body: requestData })
}

// 获取套卷题目
export function getTestPaperQuestion(requestData: { id: number }, key = 'getTestPaperQuestion') {
  const { id } = requestData
  const url = `/questionsets/${id}/question`
  return $request<databaseTypes.TestPaperInfo | { code: number }>(key, { url })
}

// 套卷题目 加入/移出 组卷
export function addOrRemoveFromTestPaper(requestData: databaseTypes.AddOrRemoveFromTestPaper, key = 'addOrRemoveFromTestPaper') {
  const { id, ...body } = requestData
  const url = `/examinationpaper/paper/${id}`
  return $request(key, { url }, { method: 'PATCH', body })
}

// 查询错题
export function searchMistakeQuestion(requestData: databaseTypes.MistakeQuestionReq, key = 'searchMistakeQuestion') {
  const url = `/mistakequestions/list`
  return $request<databaseTypes.MistakeQuestionRes>(key, { url }, { method: 'post', body: requestData })
}

// 获取章节知识点
export function getChapterKnowledge(id: number, key = 'getChapterKnowledge') {
  const url = `/chapters/${id}/knowledgepoints`
  return $request<databaseTypes.GetChapterKnowledgeRes>(key, { url })
}

// 获取标题知识点
export function getSectionKnowledge(id: number, key = 'getSectionKnowledge') {
  const url = `/sections/${id}/knowledgepoints`
  return $request<databaseTypes.GetSectionKnowdege>(key, { url })
}

// 查询添加外部题目状态
export function checkOutSideQuestionStatus(requestData: databaseTypes.CheckOutSideQuestionStatusReq, key = 'checkOutSideQuestionStatus') {
  const url = '/questions/outside/questions/status'
  return $request<databaseTypes.AddQuestionsInOutsideRes[]>(key, { url }, { method: 'post', body: requestData })
}

// 批量添加外部题目
export function addQuestionsInOutside(requestData: databaseTypes.AddQuestionsInOutsideReq[], key = 'addQuestionsInOutside') {
  const url = '/questions/outside'
  return $request<databaseTypes.AddQuestionsInOutsideRes[]>(key, { url }, { method: 'post', body: requestData })
}

// 获取全品题目列表
export function getOtherQuestions(requestData: databaseTypes.GetOtherQuestionsReq, key = 'getOtherQuestions') {
  const url = 'https://dev.gaoshouai.com/node-backend/question/list'
  // const url = 'http://10.1.0.143:3001/node-backend/question/list'
  return $request<databaseTypes.GetOtherQuestionsRes>(key, { url }, { query: requestData })
}

// 获取全品题目详情（答案解析）
export function getOtherQuestionDetail(requestData: databaseTypes.GetOtherQuestionDetailReq, key = 'getOtherQuestionDetail') {
  const url = 'https://dev.gaoshouai.com/node-backend/question/info'
  return $request<databaseTypes.GetOtherQuestionsRes>(key, { url }, { query: requestData })
}

// 获取菁优题目列表
export function getJingyouQuestions(requestData: databaseTypes.GetJingyouQuestionsReq, key = 'getOtherQuestions') {
  const url = '/python-backend/question/getQuestions'
  return $request<databaseTypes.GetJingyouQuestionsRes>(key, { url, isOther: true }, { method: 'post', body: requestData })
}

// 获取菁优题目详情（答案解析）
export function getJingyouQuestionDetail(requestData: databaseTypes.GetOtherQuestionDetailReq, key = 'getOtherQuestionDetail') {
  const url = '/python-backend/question/getQuestionDetail'
  return $request<databaseTypes.GetOtherQuestionsRes>(key, { url, isOther: true }, { query: requestData })
}

// 教材章节列表（for vue3）
export function getChapterFromDiffEdition(query: databaseTypes.GetChapterFromDiffEditionReq, key = 'getChapterFromDiffEdition') {
  const url = '/textbooks/filter'
  return $request<databaseTypes.GetChapterFromDiffEditionRes>(key, { url }, { method: 'post', query })
}
