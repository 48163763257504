<template>
  <div
    class="print_suspension"
    :class="isShow ? 'show' : 'hid'"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div
      class="p-2 cursor-pointer"
      @click="onClick"
    >
      <SvgIcon
        icon-class="print"
        size="24px"
        class="text-[#FFFFFF]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const isShow = ref<boolean>(false)

let isOpen = false // 避免误触
function onMouseEnter() {
  isOpen = true
  const timer = setTimeout(async() => {
    if (isOpen) {
      isShow.value = true
    }
    clearTimeout(timer)
  }, 200)
}

function onMouseLeave() {
  isOpen = false
  isShow.value = false
}

function onClick() {
  navigateTo('/printStatus')
}
</script>

<style lang="scss" scoped>
.print_suspension {
  position: fixed;
  top: 50%;
  right: 0;
  background: linear-gradient(-30deg, #F59D46 0%, #FFC975 100%);
  box-shadow: 0px 10px 10px 0px rgba(243,155,69,0.4);
  border-radius: 50%;
  z-index: 2000;
}
.hid {
  transform: translateX(36%);
  transition: all 0.6s ease-out;
}

.show {
  transform: translateX(0);
  transition: all 0.3s ease-in;
}
</style>
