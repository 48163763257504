// 获取年级、班级成绩数据
export function getClassData(key = 'getClassData') {
  const url = '/total/class/data'
  return $request<homeTypes.ResClassInfo>(key, { url })
}

// 根据学科展示成绩
export function getExamResultBySubject(subject: string, key = '') {
  const url = `/total/exam-results/subjects/${subject}`
  key = subject
  return $request<homeTypes.gradeExamResult>(key, { url })
}
