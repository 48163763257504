<template>
  <div class="h-screen flex items-center justify-center">
    <h1>登录状态校验中</h1>
    <ul class="strip-loading">
      <li style="--line-index: 1;" />
      <li style="--line-index: 2;" />
      <li style="--line-index: 3;" />
      <li style="--line-index: 4;" />
      <li style="--line-index: 5;" />
      <li style="--line-index: 6;" />
    </ul>
    <div class="loader-bar" />
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
h1 {
  font-size: 32px;
  font-weight: bold;
  background: linear-gradient(135deg,
    #e57627,
    #f39b44 25%,
    #fcc772 50%,
    #FFF 55%,
    #e57627 60%,
    #fcc772 80%,
    #f39b44 95%,
    #e57627);
  /* 文字颜色填充设置为透明 */
  -webkit-text-fill-color: transparent;
  /* 背景裁剪，即让文字使用背景色 */
  background-clip: text;
  /* 背景图放大一下，看着柔和一些 */
  background-size: 200% 100%;
  /* 应用动画flowCss 12秒速度 无限循环 线性匀速动画*/
  animation: flowCss 12s infinite linear;
}

@keyframes flowCss {
    0% {
        /* 移动背景位置 */
        background-position: 0 0;
    }

    100% {
        background-position: -400% 0;
    }
}
.loader-bar {
    background: repeating-linear-gradient(to right, #fcc772 0%, #f39b44 25%, #e57627 50%, #f39b44 75%, #fcc772 100%);
    height: 100px;
    background-size: 200% auto;
    background-position: 0 0;
    filter: blur(100px);
    position: fixed;
    bottom: -50px;
    left: -50px;
    right: -50px;
}

.strip-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}

.strip-loading li {
  --time: calc((var(--line-index) - 1) * 200ms);
  border-radius: 3px;
  width: 6px;
  height: 30px;
  background-color: #F59D46;
  animation: beat 1.5s ease-in-out var(--time) infinite;
  list-style-type: none;
}

li + li {
  margin-left: 5px;
}

@keyframes beat {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(.5);
  }
}
</style>
